'use client'
import { usePathname } from 'next/navigation';
import Link from 'next/link';
import { DrupalMenuLinkContent } from 'next-drupal';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { Menu as HeadlessUiMenu } from '@headlessui/react';
import { HTMLAttributes, useId, useState } from 'react';
import classNames from 'classnames';
import { getActiveTrail } from '@/lib/drupal/util';

interface MenuProps extends HTMLAttributes<any> {
    menu?: DrupalMenuLinkContent[]
    query?: string
    queryMatchBasePath?: string
}

export function Menu({ menu, queryMatchBasePath, query, ...props }: MenuProps) {
    const pathname = usePathname()

    if (!menu?.length) {
        return null;
    }

    const trail = getActiveTrail(menu, pathname)

    function inActiveTrail(url: string): boolean {
        if (!trail) {
            return false
        }
        return trail.filter(link => link.url == url).length > 0
    }

    function transformLink(link: DrupalMenuLinkContent) {
        if (query === undefined) {
            return link
        }
        if (queryMatchBasePath && !link.url.startsWith(queryMatchBasePath)) {
            return link
        }

        if (!link.url.includes('?')) {
            link.url += '?' + query
        }

        if (link.items) {
            link.items = link.items.map(item => transformLink(item))
        }
        return link
    }

    function MenuItem(item: DrupalMenuLinkContent & {
        className?: string
    }) {
        const isActive = pathname === item.url.replace(/\?.*/, '')
        const isExpanded = inActiveTrail(item.url)
        const hasLink = item.url.length > 0
        const groupId = useId()

        if (!item.items) {
            if (!hasLink) {
                console.warn("Cannot render menu link: No URL", item);
                return <></>
            }
            return <Link 
                role="menuitem" 
                className={classNames("transition-colors duration-300 hover:bg-gray-500 p-2 rounded-lg",
                    isActive ? 'font-bold bg-gray-500' : '',
                    isExpanded ? '' :  item.className
                )} 
                href={item.url}>{item.title}
            </Link>
        }

        const attrs:HTMLAttributes<HTMLDivElement> = {}
        if (item.items) {
            attrs['aria-owns'] = groupId
        }

        return <HeadlessUiMenu as={'div'} className={'flex flex-col gap-2'}>
                {({ open }) => (
                    <>
                        {isExpanded ? (
                            <div {...attrs} className='p-2'>
                                <span className={`flex-grow text-left ${item.className}`}>{item.title}</span>
                                { open && !isExpanded && (
                                    <ChevronDownIcon role="img" title="Click to collapse menu" className='print:hidden ml-2 mt-1 w-4 overflow-visible' />
                                ) }
                                { !open && !isExpanded && (
                                    <ChevronUpIcon role="img" title="Click to expand menu" className='print:hidden ml-2 mt-1 w-4 overflow-visible' />
                                )}
                            </div>
                        ): (
                            <HeadlessUiMenu.Button role={isExpanded ? 'none' : undefined} className={`${isActive && 'bg-gray-500 font-bold'} flex hover:bg-gray-500 px-2 py-1 my-1 rounded transition-colors duration-300`}>
                                <span role="menuitem" className={`flex-grow text-left ${item.className}`}>{item.title}</span>
                                { open && !isExpanded && (
                                    <ChevronDownIcon role="img" title="Click to collapse menu" className='print:hidden ml-2 mt-1 w-4 overflow-visible' />
                                ) }
                                { !open && !isExpanded && (
                                    <ChevronUpIcon role="img" title="Click to expand menu" className='print:hidden ml-2 mt-1 w-4 overflow-visible' />
                                )}
                            </HeadlessUiMenu.Button>
                        )}
                        { (open || isActive || isExpanded) && (
                            <HeadlessUiMenu.Items id={groupId} className="flex flex-col ps-5 gap-y-2" static={true}>
                                <MenuItem {...{...item, title: 'Overview', items: undefined}} />
                                { item.items?.map((link) => (
                                    <HeadlessUiMenu.Item key={link.id}>
                                        <MenuItem {...link} />
                                    </HeadlessUiMenu.Item>
                                ))}
                            </HeadlessUiMenu.Items>
                        )}
                    </>
                )}
            </HeadlessUiMenu>
    }

    if (query !== undefined) {
        menu = menu.map(link => transformLink(link))
    }

    return (
        <nav data-cy="menu" role="menu" {...props}>
            {menu.map((link, i) => <MenuItem key={link.id} className='font-bold' {...link} />)}
        </nav>
    );
}
