'use client'

import { Disclosure } from "@headlessui/react"
import { Bars3Icon, XMarkIcon } from "@heroicons/react/20/solid"
import React, { ReactNode } from "react"
import { useIFrameCompact } from "@/contexts/IFrameCompactProvider"
import useScreens from "../hooks/useScreens"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline"
import classNames from "classnames"

// Utility function to check if children are empty
const areChildrenEmpty = (children: ReactNode): boolean => {
  // Convert ReactNode children into an array
  const childrenArray = React.Children.toArray(children);

  // Check each child element
  return childrenArray.every(child =>
    child === null || // Check for null
    child === undefined || // Check for undefined
    (typeof child === 'string' && child.trim() === '') || // Check for empty strings
    (React.isValidElement(child) && React.Children.count(child.props.children) === 0) // Check for React elements with no children
  );
};


export const ResponsiveMenu = ({children, className, expandedClass, collapsedClass, panelClass, orientation = 'left', closeLabel = 'Close menu', openLabel = 'Open menu'}:{
  children: ReactNode
  className?: string
  expandedClass?: string
  collapsedClass?: string
  orientation: 'left' | 'right'
  panelClass?: string
  closeLabel?: string
  openLabel?: string
}) => {

  const { active } = useIFrameCompact()
  const key = active ? 'compact' : 'expand'
  const screen = useScreens()
  const isEmpty = areChildrenEmpty(children);

  if (isEmpty) {
    return <></>
  }

  const btnClass = orientation == 'left' ? 'lg:-right-5' : 'lg:-left-12'

  return (
    <Disclosure key={"disclosure-" + key} defaultOpen={!active}>
      {({open}) => (
        <div className={classNames(
          className, 
          open ? expandedClass : collapsedClass,
          'relative'
        )}>
          <div className="lg:sticky lg:top-32">
          <Disclosure.Button 
            title={open ? closeLabel : openLabel}
            className={classNames(
              btnClass,
              "flex items-center gap-2 print:hidden lg:top-1 lg:absolute bg-gray-400 rounded-md p-2 hover:bg-navy-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            )}>
          <span className="absolute -inset-0.5" />
          {open ? (
            screen?.lg ? (
              orientation == 'left' ? (
                <ChevronLeftIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <ChevronRightIcon className="block h-6 w-6" aria-hidden="true" />
              )
            ) : (
              <XMarkIcon className="block h-6 w-6" aria-hidden="true"  />
            )
          ) : (
            screen?.lg ? (
              orientation == 'left' ? (
                <ChevronRightIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <ChevronLeftIcon className="block h-6 w-6" aria-hidden="true" />
              )
            ) : (
              <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
            )
          )}
          <span className="pl-3 lg:sr-only">{open ? closeLabel : openLabel}</span>
          </Disclosure.Button>
          </div>
          <hr className="lg:hidden border-gray-600 mb-5 mt-2" />
          <Disclosure.Panel className={panelClass}>
            {children}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}